.ui.table .pnl-cell{
    font-size: .9em;
    padding: 2px !important;
    margin: 0;
}

/* .ui.table.pnl-table tr.pnl_row{
    background: rgb(2,0,36); 
    background: linear-gradient(-90deg, rgba(176,4,4,1) 0%, rgba(176,4,4,1) 39%, rgba(0,0,0,0) 39%, rgba(0,0,0,0) 100%);
} */

.ui.table .pnl-header{
    font-size: .9em;
    padding: 2px;
    /* margin: 0; */
    /* line-height: 100%; */
}
.ui.table.pnl-table {
    margin-bottom: 50px;
}
/* .ui.table.pnl-table tr:nth-child(odd) {
    background: #131212 !important;
} */

.ui.table .quarter-row{
    background: #202020
}

@media (min-width: 0px) and (max-width: 767px) {
    .ui.table .pnl-cell{
        font-size: .7em;
        /* padding: 0 !important; */
        margin: 0;
        line-height: 120%;
    }
    .ui.table .pnl-header{
        font-size: .8em;
        padding: 0 !important;
        margin: 0;
        line-height: 110%;
        /* text-align: center; */
    }
}





