.port-list .ui.mini.inverted.button{
    font-size: .7em;
    box-shadow: none !important;
    color: #bdbbbb;
}

.port-list .ui.mini.inverted.button.active{
    color: white;
    box-shadow: 0 0 0 2px rgb(141, 140, 140) inset!important;
}

.port-list .ui.inverted.green.button{
    background-color:#014e01;
}

.port-list .ui.inverted.blue.button{
    background-color:#01424b;
}

.port-list .ui.inverted.purple.button{
    background-color:#613a01;
}

.port-list .ui.inverted.grey.button{
    background-color:#3b3b3b;
}

.port-list .ui.inverted.red.button{
    background-color: #360101;
}

.ui.inverted.header .sub.header.port-last-update{
    color: rgb(117, 117, 117);
    font-size: .7em;
}

.port-name a{
    font-size: 1.1em;
    color: rgb(155, 155, 155);
}

.port-name a.active{
    text-decoration: underline;
    color: rgb(170, 217, 255);
}

.port-name.display-name a{
    color: rgb(202, 201, 201);
}

.port-name a.active::before{
    content: "*"
}

.port-list-row:has(.port-name){
    background-color: #360101;
}