@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./fonts/Lato/Lato-Light.ttf) format('truetype');
  font-weight: 500;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: "verdana"; */
  font-family: 'Lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f0f0f;
  color: white;
}

.form-input .ui.inverted.input input{
  background-color: rgb(63, 63, 63);
}